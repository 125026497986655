import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
  }

  switch(event) {
    const nav  = document.getElementById('team-tabs');
    const allTabButtons = document.querySelectorAll(`#${nav.id} button`);
    const targetTabButton = document.getElementById(`${this.element.dataset.target}-tab-button`);
    const targetTabPane = document.getElementById(`${this.element.dataset.target}-tab-pane`);
    const allTabPanes = Array.from(targetTabPane.parentElement.children);
    allTabButtons.forEach(button => { button.classList.remove('active') })
    targetTabButton.classList.add('active')
    allTabPanes.forEach(tabPane => { tabPane.classList.add('d-none') })
    targetTabPane.classList.remove('d-none')
    targetTabPane.classList.add('d-block')
    event.preventDefault()
  }

}
