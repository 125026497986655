import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap"

export default class extends Controller {
  connect() {
    const remoteModalElement = this.element.dataset.remoteModalId === undefined ? this.element : document.getElementById(this.element.dataset.remoteModalId)

    if(this.modal === undefined){
      this.modal = new bootstrap.Modal(remoteModalElement) ;
    }

  }
  open() {
    if (!this.modal.isOpened) {
      this.modal.show()
    }
  }

  close(event) {
    if (event.detail.success || event.detail.success === undefined) {
      this.modal.hide()
    }
  }

}
