import { Controller } from "@hotwired/stimulus";

const setTheme = theme => {
  if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.setAttribute('data-bs-theme', 'dark')
  } else {
    document.documentElement.setAttribute('data-bs-theme', theme)
  }
}

export default class extends Controller {

  connect() {

  }
  toggle(event) {

    let theme = document.documentElement.getAttribute('data-bs-theme')

    if(theme == "dark"){
      setTheme("light")
    }
    else {
      setTheme("dark")
    }
  }

}