import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap"

export default class extends Controller {
  connect() {
    this.url = this.element.getAttributeNode("href").value
  }
  click() {
    alert(this.url)
  }
}
