import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    let seconds = parseInt(this.element.getAttribute('data-seconds'));
    this.interval = setInterval(() => { this.convertToTime(seconds) }, 1000);
  }

  convertToTime(seconds) {
    this.element.textContent = new Date(seconds * 1000).toISOString().slice(11, 19);
    clearInterval(this.interval);
    this.interval = setInterval(() => { this.convertToTime(seconds + 1) }, 1000);
  }


}
