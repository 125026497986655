import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
  }

  search(event) {
    const form = this.element.form;
    const delay = 600;
    const searchCallback = () => {
      form.requestSubmit()
    }
    clearTimeout(this.timer)
    this.timer = setTimeout(searchCallback, delay)
  }

}
