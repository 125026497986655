import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  initialize(){
    this.observer = new MutationObserver(() => {
      this.scrollToActiveTurn()
    });
    this.observer.observe(this.element, { childList: true });
  }
  connect() {
    this.scrollToActiveTurn()
  }
  disconnect() {
    this.observer.disconnect();
  }
  scrollToActiveTurn() {
    document.
      getElementById('active-turn').
      scrollIntoView({ block: "center" })
  }

}
